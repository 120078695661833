// import logo from './logo.svg';
import './App.css';
import img1 from '././img/img1.png'
import img2 from '././img/img2.png'
import img3 from '././img/img3.png'
import img4 from '././img/img4.png'
import img5 from '././img/img5.png'
import img6 from '././img/img6.png'
import img7 from '././img/img7.png'
import img8 from '././img/img8.png'
import img9 from '././img/img9.png'


function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      <h3>石狮市教师进修学校附属小学</h3>
      <div>
        <p>餐饮服务食品安全等级公示栏</p>
        <img src={img1} alt="img1" className="Img" />
        <p>食品经营许可证</p>
        <img src={img2} alt="img2" className="Img" />
        <p>员工健康证</p>
        <img src={img3} alt="error" className="Img" />
        <p>石狮市食品药品日常监督检查记录表</p>
        <img src={img4} alt="img6" className="Img" />
        <p>食品安全承诺书</p>
        <img src={img5} alt="img4" className="Img" />
        <p>事业单位法人证书</p>
        <img src={img6} alt="img3" className="Img" />
        <p>品家餐饮目视板</p>
        <img src={img7} alt="img3" className="Img" />
        <p>食品安全应急预案</p>
        <img src={img8} alt="img3" className="Img" />
        <p>泉州市食品安全信息追溯主体码</p>
        <img src={img9} alt="img7" className="Img" />

      </div>

    </div>
  );
}

export default App;